import { reactive } from 'vue'
import index from '../views/index.vue'
import { createRouter, createWebHashHistory } from "vue-router";
import { getToken } from '../utils/auth';

import processScreen from '../views/processScreen/index.vue' //加工大屏
import Screen from '@/views/processScreen/screen.vue' //加工大屏
import Login from '../views/Login.vue'//登录
const routes = [
    { path: "/Screen", component: processScreen, name: 'processScreen' },
    { path: "/", component: Screen, name: 'Screen' },
    { path: "/Login", component: Login, name: 'Login' },
]


const router = createRouter({
    history: createWebHashHistory(),
    routes: routes
})
// return
router.beforeEach(async (to, from, next) => {
    const roles = localStorage.getItem('roles')
    console.log(to,'234234');
    if (to.path === "/Login") {

        next()
    } else {

        var token = getToken()
        if (token) {
            // var store = mainStore()
            // if(!store.userInfo){
            //     router.replace("/Login")//有token, 但没有userInfo，也要跳登录页面
            // }else{
            next()
        } else {
            //没有token，跳到登录
            router.replace("/Login")
        }

    }
})




// console.log("router里面")
export { router }