<template>
  <div>
    <div class="header">
      <div class="flexItems justifyBetween titleBox cont">
        <h1 class="title">今日菜单:</h1>
        <div style="font-weight: bold; margin-left: 5px" @click="outLogin">
          退出当前账号
        </div>
      </div>
    </div>
    <!-- 定位空间 -->
    <!-- 测试 -->
    <div class="headerH"></div>
    <div class="cont" v-if="purchaseOrderList && purchaseOrderList.length">
      <el-card
        :shadow="v.shadow"
        class="tradeList"
        v-for="(v, i) in purchaseOrderList"
        :key="i"
      >
        <div class="flexItems">
          <img class="listImg" :src="v.dishesImg" alt="" />
          <div class="trader flexItems">
            <div class="tradeCont">
              <div class="tradeName">
                {{ v.canteenDishesName }}：{{ v.goodsSuttleWeight }}斤
                <!-- {{ v.canteenDishesName }}：{{ v.dishesWeight }}斤 -->
                <i
                  class="iconfont icon-a-1tongyong-2Icontubiao-Line-Check-Circle iconSize"
                  :class="
                    v.goodsSuttleWeight !== v.deliveryNo
                      ? 'icon-a-1tongyong-2Icontubiao-Line-Question-Circle-Line'
                      : ''
                  "
                ></i>
              </div>
              <div class="tradeName">交货：{{ v.deliveryNo }}斤</div>
            </div>
            <div class="tradeCont">
              <div v-for="o in v.scaleProcessScreenInfoList" :key="o">
                <span class="tradeName tradeItem"
                  >{{ o.goodsName }}：{{ o.goodsSuttleWeight }}
                </span>
                <span class="tradeName tradeItem" style="margin-left: 20px;"
                  >交货：{{ o.deliveryWeight }}</span
                >
                <i
                  class="iconfont icon-a-1tongyong-2Icontubiao-Line-Check-Circle iconSize"
                  :class="
                    o.goodsSuttleWeight !== o.deliveryWeight
                      ? 'icon-a-1tongyong-2Icontubiao-Line-Question-Circle-Line'
                      : ''
                  "
                ></i>
              </div>
            </div>
          </div>
        </div>
      </el-card>
    </div>
    <el-empty description="暂无数据" v-else :image-size="200"></el-empty>
  </div>
</template>
  <script>
import {
  getCurrentInstance,
  reactive,
  ref,
  defineComponent,
  onMounted,
  onBeforeUnmount,
  toRefs,
} from "vue";
import { parseTime } from "../../utils/ruoyi.js";
import { useRouter } from "vue-router";
import { setToken } from "../../utils/auth";
import { listProcessScreen } from "../../api/requestUtil";
import { getNowFormatDate, getTomorrowDate } from "../../utils/time";
import { GetNumByUnit } from "../../utils/count.js";
import { dicts } from "../../utils/getDict";
export default defineComponent({
  name: "processScreen",
  setup() {
    const { proxy } = getCurrentInstance(); // 可以把ctx当成vue2中的this
    const router = useRouter();

    const state = reactive({
      purchaseOrderList: [],
      activeName: 1,
      formData: {
        purchaseOrder: 1,
        procureW: "100",
        supplier: "四川夸克餐饮管理有限公司",
        weigth: null,
        pName: "空心菜",
      },
      kg: 0,
      arr: [],
      refreshData: null,
      setTiem: null,
      dictObj: {},
    });
    onBeforeUnmount(() => {
      console.log("卸载之前", setTiem);
      // 清除时间函数
      clearInterval(state.setTiem);
    });
    onMounted(async () => {
      state.dictObj = await dicts(["unit_type"], state.dictObj);
      getList();
      interval();
    });
    const getList = () => {
      const date = new Date();
      const params = {
        pageNum: 1,
        pageSize: 2000,
        params: {
          beginTime: getNowFormatDate(date),
          endTime: getNowFormatDate(getTomorrowDate(date)),
        },
      };
      listProcessScreen(params).then((res) => {
        if (res.code == 200) {
          state.purchaseOrderList = arGetNumByUnit(res.rows);
        } else {
          state.purchaseOrderList = [];
          proxy.$message.error(res.msg);
        }
      });
    };
    // 数据重新组装 把数据克转为斤
    const arGetNumByUnit = (data = []) => {
      if (data.length == 0) return [];
      data.forEach((item) => {
        item.goodsSuttleWeight = GetNumByUnit(item.goodsSuttleWeight, "g", "j");

        item.deliveryNo = GetNumByUnit(item.deliveryNo, "g", "j");
        if (
          item.scaleProcessScreenInfoList &&
          item.scaleProcessScreenInfoList.length > 0
        ) {
          item.scaleProcessScreenInfoList.forEach((o) => {
            o.goodsSuttleWeight =o.goodsSuttleWeight?
              GetNumByUnit(o.goodsSuttleWeight, "g", "j") + "/斤":o.goodsSuttleWeight;
            o.deliveryWeight =  o.deliveryWeight?GetNumByUnit(o.deliveryWeight, "g", "j") + "/斤": o.deliveryWeight;
          });
        }
      });
      return data;
    };
    const interval = () => {
      state.setTiem = setInterval(() => {
        getList();
      }, 10 * 60 * 1000);
    };
    function outLogin() {
      setToken("");
      router.push("/Login");
    }
    // 字典值过滤当前值
    const dictsFilter = (dictsType, val) => {
      if (JSON.stringify(state.dictObj) == "{}") return;
      const dict = state.dictObj;

      const valObj = dict[dictsType].filter((item) => item.dictValue == val)[0];
      return (valObj && valObj.dictLabel) || "";
    };
    return {
      ...toRefs(state),
      outLogin,
      dictsFilter,
    };
  },
});
</script>
  <style scoped>
.header {
  position: fixed;
  background: #fff;
  z-index: 1;
  width: 100%;
}
.iconSize {
  font-size: 25px;
  font-weight: bold;
}
.icon-a-1tongyong-2Icontubiao-Line-Question-Circle-Line {
  color: red;
}
.justifyBetween {
  justify-content: space-between;
}
.butText {
  font-size: 23px;
}
.headerH {
  height: 70px;
}
.tradeItem {
  display: inline-block;
}
.tradeList {
  margin: 6px 0 10px;
  border-radius: 20px;
}
.trader {
  flex: 1;
  /* text-align: center; */
}

.tradeName {
  font-size: 23px;
  margin: 10px 5px;
  font-weight: bold;
}

.tradeCont {
  margin: 10px 15px;
  flex: 1;
}
.listImg {
  width: 220px;
  height: 150px;
}

.flexItems {
  display: flex;
  align-items: center;
}

.titleBox {
  margin-left: 2px;
  margin: auto;
}
.title {
  text-align: left;
  font-size: 30px;
  font-weight: bold;
  padding: 20px 0;
  /* text-indent: 20px; */
}
.cont {
  position: relative;
}
@media screen and (max-width: 1920px) and (min-width: 1430px) {
  .tradeName {
    font-size: 28px;
  }
}
@media screen and (max-width: 1430px) and (min-width: 1381px) {
  .tradeName {
    font-size: 28px;
  }
}
@media screen and (max-width: 1379px) and (min-width: 1281px) {
  .tradeName {
    font-size: 24px;
  }
}
/* 屏幕小于1280px */
@media screen and (max-width: 1280px) {
  .cont {
    width: 90%;
    margin: 0 auto;
  }
  .tradeName {
    font-size: 20px;
  }
}
/*  屏幕小于1520或大于1280 */
@media screen and (max-width: 1520px) and (min-width: 1280px) {
  .cont {
    width: 90%;
    margin: 0 auto;
  }
}
/* 屏幕大于1520px */
@media screen and (min-width: 1520px) {
  .cont {
    width: 75%;
    margin: 0 auto;
  }
}
@media screen and (max-width: 1000px) and (min-width: 800px) {
  .cont {
    width: 96%;
    margin: 0 auto;
  }
  .tradeName {
    font-size: 18px;
    margin: 10px 5px;
    font-weight: bold;
  }
  .listImg {
    width: 30%;
    height: auto;
  }
}

@media screen and (max-width: 800px) and (min-width: 600px) {
  .cont {
    width: 98%;
    margin: 0 auto;
  }
  .tradeName {
    font-size: 13px;
    margin: 10px 5px;
    font-weight: bold;
  }
  .listImg {
    width: 30%;
    height: auto;
  }
}

@media screen and (max-width: 600px) and (min-width: 500px) {
  .cont {
    width: 98%;
    margin: 0 auto;
  }
  .tradeName {
    font-size: 12px;
    margin: 10px 5px;
    font-weight: bold;
  }
  .listImg {
    width: 30%;
    height: auto;
  }
  .iconSize {
    font-size: 12px;
    font-weight: bold;
  }
}
@media screen and (max-width: 500px) {
  .cont {
    width: 98%;
    margin: 0 auto;
  }
  .tradeName {
    font-size: 12px;
    margin: 3px 5px;
    font-weight: bold;
  }
  .listImg {
    width: 25%;
    height: auto;
  }
  .iconSize {
    font-size: 10px;
    font-weight: bold;
  }
  .tradeCont {
    margin: 0px 2px;
    flex: 1;
  }
}
</style>