<!--
 * @Descripttion: 
 * @version: 
 * @Date: 2021-04-20 11:06:21
 * @LastEditors: huzhushan@126.com
 * @LastEditTime: 2022-09-27 18:24:27
 * @Author: huzhushan@126.com
 * @HomePage: https://huzhushan.gitee.io/vue3-element-admin
 * @Github: https://github.com/huzhushan/vue3-element-admin
 * @Donate: https://huzhushan.gitee.io/vue3-element-admin/donate/
-->
<template>
	<div class="login">
		<el-form class="form" :model="model" :rules="rules" ref="loginForm">
			<div class="logoWrap">
				<img src="../assets/login/logo.png" />
				<div class="loginText">厨创数智餐厅</div>
			</div>
			<div class="loginWBox" v-if="isLoginW">
				<img
					src="https://files.axshare.com/gsc/X2432I/2c/d3/68/2cd368e97e964eb5a416fe2306e3b553/images/%E7%99%BB%E5%BD%95%E7%95%8C%E9%9D%A2/u1.svg?pageId=e07e555d-4399-424f-96c6-f1e73f438958" />
				<div class="loginW">请用微信扫码登录</div>
			</div>
			<template v-else>
				<el-form-item prop="mobile">
					<el-input size="large" class="text" v-model="model.mobile" clearable>
						<template #prepend> 手机号 </template>
						<template #append>
							<div class="text" @click="codeClick">{{ tips }}</div>
						</template>
					</el-input>
				</el-form-item>
				<el-form-item prop="smsCode">
					<el-input class="text" size="large" v-model="model.smsCode" clearable>
						<template #prepend> 动态码 </template>
					</el-input>
				</el-form-item>
			</template>

			<el-button type="success" style="margin-bottom: 15px" :style="{ textIndent: isLoginW ? '40px' : '' }" link
				@click="switchClick">{{ !isLoginW ? "切换为微信扫码登录" : "切换为手机登录" }}</el-button>
			<el-form-item v-if="!isLoginW">
				<el-button :loading="loading" type="success" class="btn" size="large"
					@click="submit">{{ btnText }}</el-button>
			</el-form-item>
		</el-form>

		<!-- 底部 -->
		<div class="index-footer">
			<span>Copyright ©2023 福建厨创科技有限公司 All Rights Reserved. <a href="https://beian.miit.gov.cn/" target="_blank">闽ICP备2022009217号-1</a> </span>
		</div>

	</div>
</template>

<script>
	import {
		defineComponent,
		getCurrentInstance,
		reactive,
		toRefs,
		ref,
		computed,
		onMounted,
		watch,
	} from "vue";
	import {
		setToken
	} from "../utils/auth";
	import {
		useRouter,
		useRoute
	} from "vue-router";
	import wx from "weixin-js-sdk";
	import {
		post,
		get,
		postFun
	} from "../api/requestUtil";

	export default defineComponent({
		name: "login",
		setup() {
			const {
				proxy
			} = getCurrentInstance(); // 可以把ctx当成vue2中的this
			const router = useRouter();

			const getRules = () => ({
				mobile: [{
					required: true,
					message: "请输入手机号",
					trigger: "blur",
				}, ],
				smsCode: [{
						required: true,
						message: "请输入手机动态码",
						trigger: "blur",
					},
					{
						min: 6,
						max: 6,
						message: "请输入六位手机动态码",
						trigger: "blur",
					},
				],
			});
			const state = reactive({
				model: {
					mobile: "",
					smsCode: "",
					loginType: "warehouse_scale",
				},
				isLoginW: false,
				timeleft: 60,
				tips: "获取验证码",
				rules: getRules(),
				loading: false,
				btnText: "登录",
				loginForm: ref(null),
				submit: async () => {
					/**
					 * 登录类型
					 *  "warehouse_scale": 仓管称端
					 *  "process_scale": 加工称端
					 * "process_screen":加工屏幕
					 * "canteen_scale": 餐厅称端
					 */

					// 判断登录人，需要进那个模块
					const res = await postFun("/auth/scale-login", state.model);
					const data = res.data;
					const roles = data.roles;
					localStorage.setItem("roles", roles);
					localStorage.setItem("userId", data.userId);
					localStorage.setItem("userName", data.userName);
					if (roles.includes("process_screen")) {
						setToken(data.access_token);
						router.push("/");
					} else {
						proxy.$message.warning("该手机号未注册");
						router.push("/login");
					}
					// 仓管秤端 (需要锁屏)
					// router.push({ path: "Clock", state: { params: { type: "0" } } });
				},
			});
			const switchClick = () => {
				state.isLoginW = !state.isLoginW;
			};
			onMounted(() => {
				// loadWX
			});
			const loadWX = () => {
				const url = encodeURIComponent(window.location.href.split("#")[0]);
				// 发起后端请求获取微信JS-SDK配置
				axios.get(`/api/wechat/jssdk?url=${url}`).then((res) => {
					this.wxConfig = res.data.wxConfig;
					// 配置微信JS-SDK
					wx.config(this.wxConfig);
					wx.ready(() => {
						// 微信JS-SDK已准备就绪
					});
				});
			};
			// 执行微信扫码登录
			const scanQRCode = () => {
				wx.scanQRCode({
					needResult: 1,
					scanType: ["qrCode", "barCode"],
					success: (res) => {
						const result = res.resultStr; // 获取二维码扫描结果
						// 发起后端请求获取用户的openid和access_token
						axios.post("/api/wechat/userinfo", {
							result
						}).then((userinfo) => {
							// 保存用户信息、登录或注册等操作
							console.log(userinfo);
						});
					},
					fail: (res) => {
						console.log(res);
					},
				});
			};

			// 发起验证码请求接口
			const codeClick = async () => {
				if (!state.model.mobile && state.model.mobile.length != 11)
					return proxy.$message.warning("请输入正确的手机号");
				getCode();
				const code = await get("system/message/send-mobile-code", {
					mobile: state.model.mobile,
				});
				state.timeleft = 60;
			};

			// 获取验证码
			const getCode = () => {
				var downloadTimer = setInterval(function() {
					state.timeleft--; // 时间减一秒
					state.tips = state.timeleft + "获取"; // 显示时间到页面上
					if (state.timeleft <= 0) {
						// 当倒计时结束时
						clearInterval(downloadTimer); // 停止计时器
						state.tips = "重新获取"; // 显示倒计时结束到页面上
					}
				}, 1000); // 每秒执行一次
			};

			return {
				...toRefs(state),
				switchClick,
				codeClick,
				// loadWX,
				// scanQRCode
			};
		},
	});
</script>

<style scoped>
	.loginW {
		font-size: 22px;
		font-weight: bold;
		margin: 20px 0;
	}

	.loginWBox {
		margin: 0 auto;
		text-align: center;
	}

	.login {}

	>>>.el-input__wrapper {
		border-radius: 22px;
		padding: 1px 10px !important;
		box-shadow: -1px 0px 0 1px #dcdfe6 inset;
	}

	>>>.el-input__wrapper.is-focus {
		box-shadow: 0px 0px 0 1px #95d475 inset;
	}

	.text {
		color: #95d475;
	}

	>>>.el-input-group__prepend {
		border-top-left-radius: 22px;
		border-bottom-left-radius: 22px;
		border-bottom-left-radius: 22px;
		/* box-shadow: -1px 0px 0 1px #dcdfe6; */
		border: 1px solid #dcdfe6;
		box-shadow: none;
		border-right: none;
		background: #fff;
	}

	>>>.el-input-group__append {
		border-top-right-radius: 22px;
		border-bottom-right-radius: 22px;
		border-bottom-right-radius: 22px;
		border: 1px solid #dcdfe6;
		box-shadow: none;
		border-left: none;
		background: #fff;
		color: #95d475;
	}

	.loginText {
		margin-top: 20px;
	}

	.logoWrap {
		padding-bottom: 45px;
		text-align: center;
		margin: 0 auto;
	}

	img {
		width: 130px;
		height: 130px;
	}

	.form {
		/* width: 395px; */
		/* width: 40%; */
		/* max-width: 100%; */
		padding: 0 24px;
		box-sizing: border-box;
		margin: 160px auto 0;
	}

	@media screen and (max-width: 1921px) {
		.form {
			width: 40%;
		}
	}

	@media screen and (max-width: 1200px) and (min-width: 1921px) {
		.form {
			width: 40%;
		}
	}

	/* 屏幕大于900px */
	@media screen and (max-width: 1200px) {
		.form {
			width: 60%;
		}
	}

	@media screen and (max-width: 700px) {
		.form {
			width: 100%;
		}
	}

	.title {
		color: #fff;
		text-align: center;
		font-size: 24px;
		margin: 0 0 24px;
	}

	.text {
		font-size: 16px;
	}

	/deep/.el-input__inner {
		/* height: 48px;
  line-height: 48px; */
	}

	.btn {
		width: 100%;
	}

	.change-lang {
		position: fixed;
		right: 20px;
		top: 20px;
	}

	.change-lang:deep {}

	.change-lang {
		height: 24px;
	}

	.change-lang:hover {
		background: none;
	}

	.icon {
		color: #fff;
	}
	.index-footer {
	   height: 40px;
	   line-height: 40px;
	   position: fixed;
	   bottom: 0;
	   width: 100%;
	   text-align: center;
	   color: #201e1ed9;
	   font-family: Arial;
	   font-size: 12px;
	   letter-spacing: 1px;
	 }
</style>