<template>
	<el-container class="layout-container-demo">
		<!-- 顶部工具栏 -->
		<el-header>今日菜单</el-header>
		<!-- 内容 -->
		<el-main  style="padding:5px">
            <el-scrollbar>
                 <div class="scrollbar-flex-content" >
                    <el-empty description="暂无数据" style="margin:0 auto" v-if="tableData.length==0" />
                    <div v-for="(item,index) in tableData" :key="index" class="scrollbar-demo-item">
                        <el-card style="height: 100%;min-width:280px;width: 100%;"   class="scale-card">
                            <img :src="item.dishesImg" alt=""   style="height: 180px;width: 100%;border-radius:8px" />
                            <div style="padding:0 15px;margin-top: 2px">
                            <!-- 菜品 -->
                            <div class="dishes-text">菜品</div>
                            <div class="dishes-name">{{item.canteenDishesName}}<span class="dishes-weight">{{item.goodsSuttleWeight}}斤</span></div>
                            <div class="delivery-box flex">
                                <div class="delivery-text" :class="{'orange-bag':item.goodsSuttleWeight !== item.deliveryNo}">交货</div>
                                <div class="delivery-text delivery-weight" :class="{'orange-bag orange-color':item.goodsSuttleWeight !== item.deliveryNo}">{{item.deliveryNo}}斤</div>
                            </div>
                            <!-- 配方 -->
                            <div class="dishes-text">配方</div>
                            <div v-for="(formula,index) in item.scaleProcessScreenInfoList" :key="index"  >
                            <div class="dishes-name formula-text">{{formula.goodsName}}<span class="dishes-weight">{{formula.goodsSuttleWeight}}斤</span></div>
                            <div class="delivery-box formula-text flex">
                                <div class="delivery-text" :class="{'orange-bag':formula.goodsSuttleWeight !== formula.deliveryWeight}">交货</div>
                                <div class="delivery-text delivery-weight" :class="{'orange-bag orange-color':formula.goodsSuttleWeight !== formula.deliveryWeight}">{{formula.deliveryWeight}}斤</div>
                            </div>
                            </div>
                        </div>
                    </el-card>
                
                </div>
            </div>
        </el-scrollbar>
    </el-main>
	</el-container>
</template>

<script lang="ts" setup>
	import { onBeforeUnmount, ref } from "vue";
	import { useRouter } from "vue-router";
    import { listProcessScreen } from "@/api/requestUtil";
    import { getNowFormatDate, getTomorrowDate } from "@/utils/time";
    import { GetNumByUnit } from "@/utils/count.js";
    import { ElMessage } from "element-plus";
	import { setToken } from "@/utils/auth";
	const router = useRouter();
    // 定时器
    const setTiem = ref(null);




	const item = {
		date: "2016-05-02",
		name: "Tom",
		address: "No. 189, Grove St, Los Angeles",
	};
	const tableData = ref(Array.from({ length: 20 }).fill(item));

	// 列表请求
	const getScaleList = async () => {
		// 当天时间
		const date = new Date();

		// 请求参数
		const params = {
			pageNum: 1,
			pageSize: 2000,
			params: {
                beginTime: getNowFormatDate(date),
                endTime: getNowFormatDate(getTomorrowDate(date)),
			},
			status: 0
		}

        listProcessScreen(params).then((res) => {
            if (res.code == 200) {
                tableData.value = arGetNumByUnit(res.rows);
            } else {
                tableData.value =[]
                ElMessage.error(res.msg)
            }
        }).catch((err) => {
            ElMessage.error(err.message)
        })
	}
	getScaleList()

       // 数据重新组装 把数据克转为斤
    const arGetNumByUnit = (data = []) => {
      if (data.length == 0) return [];
      data.forEach((item) => {
        item.goodsSuttleWeight = GetNumByUnit(item.goodsSuttleWeight, "g", "j");

        item.deliveryNo = GetNumByUnit(item.deliveryNo, "g", "j");
        if (
          item.scaleProcessScreenInfoList &&
          item.scaleProcessScreenInfoList.length > 0
        ) {
          item.scaleProcessScreenInfoList.forEach((o) => {
            o.goodsSuttleWeight =o.goodsSuttleWeight?
              GetNumByUnit(o.goodsSuttleWeight, "g", "j") :o.goodsSuttleWeight;
            o.deliveryWeight =  o.deliveryWeight?GetNumByUnit(o.deliveryWeight, "g", "j") : o.deliveryWeight;
          });
        }
      });
      return data;
    };

    onBeforeUnmount(() => {
        // 清除时间函数
      clearInterval(setTiem.value);
    })
    // 10分钟定时器 请求一次数据
    const interval = () => {
      clearInterval(setTiem.value);
    setTiem.value = setInterval(() => {
        console.log('10分钟已过');
        getScaleList();
        interval()
      }, 10 * 60 * 1000);
    };
    interval()

</script>

<style scoped>

.scrollbar-flex-content {
  display: flex;
  height: 100%;
}
.scrollbar-demo-item {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width:calc(100% / 7);
  margin: 10px;
  text-align: center;

}

.dishes-text{
    font-size: 14px;
    background: rgba(77, 175, 255, 1);
    width: 40px;
    color: #fff;
    text-align: center;
    padding: 3px;
    border-radius: 8px;
}
.delivery-box{
    font-size: 28px;
    margin-left: 10px;
    margin-bottom: 10px;
}
.delivery-text{
    color: #fff;
    background:rgba(152, 224, 0, 1) ;
    border:1px solid rgba(152, 224, 0, 1) ;
    border-radius: 8px;
    padding: 6px;
    min-width: 70px;
    text-align: center;
}

.delivery-weight{
    color:rgba(152, 224, 0, 1) ;
    background: #fff;
    flex: 1;
    border-width: 3px;
    padding: 3px;
    margin-left:6px ;
}
	.layout-container-demo {
		font-size: 25px;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		position: fixed;
		width: 100%;
		height: calc(100vh - 15px);
		top: 0;
		margin-top: 15px;
		font-size: 36px;
	}

	.layout-container-demo .el-header {
		position: relative;
		width: 16%;
		background-color: rgba(152, 205, 255, 1);
		color: #fff;
		height: 60px;
		border-top-right-radius: 50px;
		border-bottom-right-radius: 50px;
		line-height: 60px;
		font-weight: 600;
	}
    .dishes-name{
        color: #000;
        font-weight: 600;
        font-size: 30px;
        margin: 15px 0 10px;
        margin-left: 10px;
        text-align: left;
    }
    .dishes-weight{
    font-size: 24px;
   margin-left: 10px;
    }
    .formula-text{
    color:#797979 ;
    font-size: 28px;
    text-align: center;
}
.orange-bag{
    background: rgba(255, 169, 96, 1);
    border-color: rgba(255, 169, 96, 1);
}
.orange-color{
    color:rgba(255, 169, 96, 1);
    background: #fff;
}

	/* 屏幕小于1280px */
	@media screen and (max-width: 1280px) {
		.cont {
			width: 90%;
			margin: 0 auto;
		}
	}

	/*  屏幕小于1520或大于1280 */
	@media screen and (max-width: 1520px) and (min-width: 1280px) {
		.cont {
			width: 90%;
			margin: 0 auto;
		}
	}

	/* 屏幕大于1520px */
	@media screen and (min-width: 1520px) {
		.cont {
			width: 75%;
			margin: 0 auto;
		}
	}
</style>

<style lang="scss">
.scale-card {
    height: 100%;min-width:280px;
    width: 100%;
    border-radius: 8px !important;
    color: #333;
        text-align: center;

    .el-card__body {
		padding: 0;
	}
}
.layout-container-demo .el-scrollbar__view{
    height: 100%;
}
</style>