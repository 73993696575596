//加法
export function accAdd(arg1 = 0, arg2 = 0) {
    var r1, r2, m, c;
    try {
        r1 = arg1.toString().split(".")[1].length;
    }
    catch (e) {
        r1 = 0;
    }
    try {
        r2 = arg2.toString().split(".")[1].length;
    }
    catch (e) {
        r2 = 0;
    }
    c = Math.abs(r1 - r2);
    m = Math.pow(10, Math.max(r1, r2));
    if (c > 0) {
        var cm = Math.pow(10, c);
        if (r1 > r2) {
            arg1 = Number(arg1.toString().replace(".", ""));
            arg2 = Number(arg2.toString().replace(".", "")) * cm;
        } else {
            arg1 = Number(arg1.toString().replace(".", "")) * cm;
            arg2 = Number(arg2.toString().replace(".", ""));
        }
    } else {
        arg1 = Number(arg1.toString().replace(".", ""));
        arg2 = Number(arg2.toString().replace(".", ""));
    }
    return (arg1 + arg2) / m;
}
//减法

export function accSub(arg1 = 0, arg2 = 0) {
    var r1, r2, m, n;
    try {
        r1 = arg1.toString().split(".")[1].length;
    }
    catch (e) {
        r1 = 0;
    }
    try {
        r2 = arg2.toString().split(".")[1].length;
    }
    catch (e) {
        r2 = 0;
    }
    m = Math.pow(10, Math.max(r1, r2)); //last modify by deeka //动态控制精度长度
    n = (r1 >= r2) ? r1 : r2;
    return ((arg1 * m - arg2 * m) / m).toFixed(n);
}
//乘法
export function accMul(arg1 = 0, arg2 = 0) {
    var m = 0, s1 = arg1.toString(), s2 = arg2.toString();
    try {
        m += s1.split(".")[1].length;
    }
    catch (e) {
    }
    try {
        m += s2.split(".")[1].length;
    }
    catch (e) {
    }
    return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
}

// 重量转换
export let GetNumByUnit = function (num = 0, unitname, outunitname, isTofix = false) {
    var fRate = {//换算率
        ng: { μg: 0.001, mg: 0.001 * 0.001, g: 0.001 * 0.001 * 0.001, kg: 0.001 * 0.001 * 0.001 * 0.001, t: 0.001 * 0.001 * 0.001 * 0.001 * 0.001, ul: 0.001 * 0.001, ml: 0.001 * 0.001 * 0.001, L: 0.001 * 0.001 * 0.001 * 0.001 },
        μg: { ng: 1000, mg: 0.001, g: 0.001 * 0.001, kg: 0.001 * 0.001 * 0.001, t: 0.001 * 0.001 * 0.001 * 0.001, ul: 0.001, ml: 0.001 * 0.001, L: 0.001 * 0.001 * 0.001 },
        mg: { ng: 1000 * 1000, μg: 1000, g: 0.001, kg: 0.001 * 0.001, t: 0.001 * 0.001 * 0.001, ul: 1, ml: 0.001, L: 0.001 * 0.001 },
        g: { ng: 1000 * 1000 * 1000, μg: 1000 * 1000, mg: 1000, kg: 0.001, t: 0.001 * 0.001, ul: 1000, ml: 1, L: 0.001, j: 0.002 },
        kg: { ng: 1000 * 1000 * 1000 * 1000, μg: 1000 * 1000, mg: 1000, g: 1000, t: 0.001, ul: 1000 * 1000, ml: 1000, L: 1 ,j:2},
        j: {  g: 500, kg:0.5 },
        t: { ng: 1000 * 1000 * 1000 * 1000 * 1000, μg: 1000 * 1000 * 1000, mg: 1000 * 1000, g: 1000 * 1000, kg: 1000, ul: 1000 * 1000 * 1000, ml: 1000 * 1000, L: 1000 ,j:2000},
        ml: { ng: 1000 * 1000 * 1000, μg: 1000 * 1000, mg: 1000, g: 1, kg: 0.001, t: 0.001 * 0.001, ul: 1000, L: 0.001 },
        ul: { ng: 1000 * 1000, μg: 1000, ml: 1, g: 0.001, kg: 0.001 * 0.001, t: 0.001 * 0.001 * 0.001, ml: 0.001, L: 0.001 * 0.001 },
        L: { ng: 1000 * 1000 * 1000 * 1000, μg: 1000 * 1000, mg: 1000, g: 1000, kg: 1, t: 0.001, ul: 1000 * 1000, ml: 1000 },
    };
    // var tnum = (num * fRate[unitname][outunitname]).toFixed(4);
    var tnum = (num * fRate[unitname][outunitname]);
    let tnums = toFixedDigit(tnum, 3)
    // console.log(tnums,'234');
    // return tnum;
    if (isTofix) {
        return tnum
    }
    return tnums;
};

function toFixedDigit(num, n) {//保留n位
    if (typeof num != 'number') {
        return false;
    };
    if (n <= 0) {//不保留小数，取整
        return num | 0;
    };
    num = num.toString();
    var result = "";
    var zeroResult = function (n) {
        var zero = "";
        for (var i = 0; i < n; i++) {
            zero += "0";
        }
        return zero;
    }
    if (num % 1 == 0) {//整数
        result = num + "." + zeroResult(n);
    } else {//小数
        var num1 = num.split(".");
        if (num1[1].length < n) {
            result = num1[0] + "." + num1[1] + zeroResult(n - num1[1].length)
        } else {
            result = num1[0] + "." + num1[1].substring(0, n)
        }
    }


    return Number(result);
}
// var result1 = toFixedDigit(8.7,2)
// console.log(result1)//8.70
// var result2 = toFixedDigit(8,2)
// console.log(result2)//8.00
// var result3 = toFixedDigit(8.6999,2)
// console.log(result3)//8.69
// var result4 = toFixedDigit(85.6999765,4)
// console.log(result4)//85.6999
